import React from "react"
import styled from "styled-components"
import GoogleMapReact from "google-map-react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import LastCTASection from "../components/lastCTASection"
import Popup from "../components/popup"
import ContactForm from "../components/contactForm"
import {
  BigMobileFont,
  MediumMobileFont,
  SmallMobileFont,
} from "../components/typography"
import BaseImg from "../components/baseImg"
import marker from "../images/marker.png"

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 84px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const HeroImage = styled(BaseImg)`
  margin: 0;
  position: relative;
  z-index: -1;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const HeroContent = styled.div`
  margin: 0 auto 0 -200px;
  padding: 63px 32px;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 780px;
  background-color: #fff;
  @media (max-width: 1195px) {
    padding: 32px;
  }
  @media (max-width: 768px) {
    padding: 32px 16px;
    margin: -50px auto 0;
  }
`

const Contact = ({ data }) => {
  const {
    contactFormTitle,
    contactMailPrefix,
    contactPhoneNumberPrefix,
    contactCity,
    contactCompanyName,
    contactMail,
    contactImage,
    contactPhoneNumber,
    firstSectionText,
    firstSectionTitle,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
  } = data.wordpressPage.cmb2.metabox_contact
  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/contact"
      />
      <Container size="medium">
        <CenterBorderedHeader isH1>
          <BigMobileFont desktopFontSize="2.5rem">
            {firstSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <div
          style={{
            maxWidth: "520px",
            margin: "40px auto",
            textAlign: "center",
          }}
        >
          <MediumMobileFont>{firstSectionText}</MediumMobileFont>
        </div>
        <HeroSection>
          <HeroImage alt="" localFile={contactImage.localFile} />
          <HeroContent>
            <div>
              <SmallMobileFont desktopFontSize="1.125rem">
                {contactCompanyName}
              </SmallMobileFont>
            </div>
            <div style={{ margin: "0.75rem 0" }}>
              <div style={{ fontWeight: "700" }}>
                <SmallMobileFont desktopFontSize="1.5rem">
                  {`${contactPhoneNumberPrefix} `}
                  <a href={`tel:${contactPhoneNumber}`}>{contactPhoneNumber}</a>
                </SmallMobileFont>
              </div>
              <div style={{ fontWeight: "700", wordBreak: "break-word" }}>
                <SmallMobileFont desktopFontSize="1.5rem">
                  {`${contactMailPrefix} `}
                  <a href={`mailto:${contactMail}`}>{contactMail}</a>
                </SmallMobileFont>
              </div>
            </div>
            <div>
              <SmallMobileFont desktopFontSize="1.125rem">
                {contactCity}
              </SmallMobileFont>
            </div>
          </HeroContent>
        </HeroSection>
        <ContactForm title={contactFormTitle} />
      </Container>

      <div style={{ height: "530px", width: "100%", marginTop: "80px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDfM5hh2EkZiS3pyUW-OhMOmWQmYasVAtg" }}
          defaultCenter={{ lat: 52.40132707771192, lng: 16.904079456050187 }}
          defaultZoom={13}
          options={{
            styles: [
              {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "transit",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "landscape",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                ],
              },
              {
                featureType: "transit",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                ],
              },
              {
                featureType: "water",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                ],
              },
            ],
          }}
        >
          <a
            lat={52.40132707771192}
            lng={16.904079456050187}
            target="_blank"
            href="https://www.google.pl/maps/place/BLUE+OWL+%7C+Interaktywny+Software+House+Pozna%C5%84/@52.4144493,16.8695393,17z/data=!3m1!4b1!4m5!3m4!1s0x47044531cb2b0c37:0xb50e0151dd8ff374!8m2!3d52.414446!4d16.871728"
            rel="noopener noreferrer"
          >
            <img
              style={{ height: "30px", transform: "translate(-50%,-100%)" }}
              src={marker}
              alt=""
            />
          </a>
        </GoogleMapReact>
      </div>

      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonUrl={lastCTASectionButtonUrl}
        buttonText={lastCTASectionButtonText}
      />
      <Popup />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    wordpressPage(template: { eq: "page-templates/page-contact.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_contact {
          contactFormTitle
          contactMailPrefix
          contactPhoneNumberPrefix
          contactCity
          contactCompanyName
          contactMail
          contactPhoneNumber
          contactImage {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          firstSectionText
          firstSectionTitle
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          lastCTASectionText
          lastCTASectionTitle
        }
      }
    }
  }
`
